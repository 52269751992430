.main-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-container img {
  width: 40%;
}


.main-container h1 {
  color: var(--Text);
}